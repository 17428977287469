// ===============================
//
// Viewport
//
// ===============================

var viewport = {
	selectors: {
		window: $(window)
	},
	getWidth: function() {
		return this.selectors.window.width();
	},
	getHeight: function() {
		return this.selectors.window.height();			
	},
	getScroll: function() {
		return this.selectors.window.scrollTop();				
	},
	init: function() {
		debug.output(this.getWidth());
		debug.output(this.getHeight());
		debug.output(this.getScroll());
	}
};