/*
* Slideshow Helper
* ------------
* Extends Owl Carousel
* ------------
* Version: 1.0
* Website: madebyshape.co.uk
* Author: Shape (@madebyshape)
* Dependencies: Owl Carousel
*/



	if ($('.js-slideshow-container').length > 0) {

		$('.js-slideshow-container')
			.waitForImages().done(

				function() {

					$('.js-slideshow-loader')
						.delay(1000)
						.queue(

							function(next) {

								$('.js-slideshow-loader')
									.removeClass(state.active);

							}

						);

				$('.js-slideshow')
					.each(

						function(index){

							//
							// Variables
							//

								var slideshowObject = $(this),
									slideshowSpeed = 500,
									slideshowActiveCount = 0;

							//
							// Options
							//

								var slideshowOptions,
									slideshowOptTabs = slideshowObject.data('slideshow-tabs'),
									slideshowOptAuto = slideshowObject.data('slideshow-auto'),
									slideshowOptType = slideshowObject.data('slideshow-type');

								var viewportPadding = ($(window).width() * 0.05) + 30;

							//
							// Add Required Owl Class
							//

								slideshowObject.addClass('owl-carousel');

							//
							// Options
							//

								if (slideshowOptType === 'default') {

										slideshowOptions = {
											smartSpeed: slideshowSpeed,
											items: 1,
											nav: false,
											dots: false,
											loop: false,
										}

								}
								else if (slideshowOptType == 'heroSlideshow') {

										slideshowOptions = {
											smartSpeed: slideshowSpeed,
											items: 1,
											margin: 0,
											loop: true,
											nav: false,
											dots: true,
											autoplay: true,
											mouseDrag: false,
											touchDrag: false,
											animateOut: 'fadeOut'
										}

								}
								else if (slideshowOptType == 'imageSlideshow') {

										slideshowOptions = {
											smartSpeed: slideshowSpeed,
											items:1,
											loop: true,
											nav: true,
											navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
											dots: true,
											autoplay: true,
											animateOut: 'fadeOut',
											mouseDrag: false,
											touchDrag: false,
										}

								}
								else if (slideshowOptType == 'largeSlideshow') {

									slideshowOptions = {
										smartSpeed: slideshowSpeed,
										items: 1,
										margin: 50,
										loop: true,
										nav: true,
										navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
										dots: false,
										stagePadding: 100,
										autoplay: true,
										mouseDrag: false,
										touchDrag: false,
									}

								}
								else if (slideshowOptType == 'testimonialSlideshow') {

										slideshowOptions = {
											smartSpeed: slideshowSpeed,
											items:1,
											loop: false,
											nav: false,
											dots: false,
											autoplay: false,
											mouseDrag: false,
											touchDrag: false,
											animateOut: 'fadeOut'
										}

								}
								else if (slideshowOptType == 'socialSlideshow') {

										slideshowOptions = {
											smartSpeed: slideshowSpeed,
											items:6,
											loop: false,
											nav: true,
											navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
											dots: false,
											autoplay: true,
											mouseDrag: false,
											touchDrag: false,
											responsive: {
												0 : {
													items: 1
												},
												600 : {
													items: 2
												},
												960 : {
													items: 3
												},
												1100 : {
													items: 3
												},
												1400 : {
													items: 4
												},
												1600 : {
													items: 5
												},
												2000 : {
													items: 6
												}
											}
										}

								}

							//
							// Create
							//

								var slideshowObj = slideshowObject.owlCarousel(slideshowOptions),
									slideshowPaginationObj = $('[data-slideshow-pagination=' + slideshowOptType + ']'),
									slideshowNext = $('[data-slideshow-next=' + slideshowOptType + ']'),
									slideshowPrev = $('[data-slideshow-prev=' + slideshowOptType + ']');

								var	slideshowItems = $(this).find('.owl-item').length,
									slideshowClonesItems = $(this).find('.owl-item.cloned').length / 2,
									slideItemCount = slideshowItems - (slideshowClonesItems * 2);


							//
							// Init Callback
							//

								// Hack around to make images work with autoHeight

								setTimeout(
									function() {
										slideshowObj.data('owlCarousel').updateVars();
										slideshowObj.trigger('next.owl.carousel');
										slideshowObj.trigger('prev.owl.carousel');
									},
									1000
								);

							//
							// On Resize
							//

								// Hack around to make images work with autoHeight

								slideshowObject
									.on(
										'resized.owl.carousel',
										function(event) {

											slideshowObj.owlCarousel('destroy');
											slideshowObj.owlCarousel(slideshowOptions);

											slideshowObj.data('owlCarousel').updateVars();
											slideshowObj.trigger('next.owl.carousel');
											slideshowObj.trigger('prev.owl.carousel');

										}
									);

							//
							// Functions
							//

								// Change Slide

									function slideshowChangeSlide(index) {

										var calc = index + slideshowClonesItems;

										slideshowObj.trigger('to.owl.carousel', [index, slideshowSpeed, true]);
										slideshowChangePagination(calc);

									}

								// Change Pagination

									function slideshowChangePagination(index) {

										var calc = index - slideshowClonesItems;

										if (calc < 0) {

											var slideItemCountIndex = slideItemCount - 1;

											slideshowPaginationObj
												.children('li[data-count=' + slideItemCountIndex + ']')
												.addClass('active');

										} else {

											slideshowPaginationObj
												.children('li[data-count=' + calc + ']')
												.addClass('is-active');

										}

										// Add Ons - Start
										// Add Ons - End

									}

							//
							// Pagination
							//

								// Set First Pagination Element Active

									slideshowPaginationObj
										.children('li:first-child')
										.addClass('is-active');

								// Click

									slideshowPaginationObj
										.children('li')
										.click(
											function(event){

												slideshowActiveCount = $(this).data('count');
												slideshowChangeSlide(slideshowActiveCount);
												event.preventDefault();

											}
										);

								// Change - Start

									slideshowObj
										.on(
											'change.owl.carousel',
											function(event) {

												slideshowPaginationObj
													.children('li')
													.removeClass('is-active');

											}
										);

								// Change - Finished

									slideshowObj
										.on(
											'translate.owl.carousel',
											function(event) {

												slideshowActiveCount = event.item.index;
												slideshowChangePagination(slideshowActiveCount);

											}
										);

							//
							// Arrows
							//

								slideshowNext
									.click(
										function(event){
											slideshowObj.trigger('next.owl.carousel');
											event.preventDefault();
										}
									);

								slideshowPrev
									.click(
										function(event){
											slideshowObj.trigger('prev.owl.carousel');
											event.preventDefault();
										}
									);

							//
							// Addons
							//


						}

					);

				}

			);

	}
