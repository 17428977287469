// ===============================
//
// Debug
//
// ===============================

var debug = {
	settings: {
		enabled: true
	},
	output: function(string) {
		if (this.settings.enabled) {
			console.log(string);
		}
	}
};