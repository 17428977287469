// ===============================
//
// Variables
//
// ===============================

	// Resize Width
	var windowResizeWidth = viewport.getWidth();

	// Breakpoints (Match _settings.scss)
	var breakpoint = {
		small: 0,
		medium: 640,
		large: 1024,
		xlarge: 1200,
		xxlarge: 1440
	}

	// States
	var state = {
		hidden: 'is-hidden',
		visible: 'is-visible',
		selected: 'is-selected',
		active: 'is-active',
		inactive: 'is-inactive',
		removed: 'is-removed',
		processing: 'is-processing',
		loading: 'is-loading'
	}

// ===============================
//
// Modules
//
// ===============================

	// Module

	var module = {
		variables: {
			var: null
		},
		init: function() {

		}
	}

	// featureLinks

	var featureLinks = {
		init: function() {

			$('.js-feature-links-item')
				.hover(

					function() {

						var id = $(this).data('id');

						$('.js-feature-links-item')
							.removeClass(state.active)
							.addClass(state.inactive);

						$(this)
							.removeClass(state.inactive)
							.addClass(state.active);

						$('.js-feature-links-hover-image-item')
							.removeClass(state.active);

						$('.js-feature-links-hover-image-item[data-id=' + id + ']')
							.addClass(state.active);

					},

					function() {

						$('.js-feature-links-item')
							.removeClass(state.inactive)
							.removeClass(state.active);

						$('.js-feature-links-hover-image-item')
							.removeClass(state.active);

					}

				);

		}
	}

	// SVG

	var svg = {
		init: function() {

			$('.js-svg-convert').svgConvert();

		}
	}

	// Gradient

	var sectionGradient = {
		init: function() {

			$('.js-section-gradient-button')
				.hover(

					function() {

						$('.js-section-gradient, .js-section-background-image, .js-section-gradient-button')
							.addClass(state.active);

					},

					function() {

						$('.js-section-gradient, .js-section-background-image, .js-section-gradient-button')
							.removeClass(state.active);

					}

				);

		}
	}

	// Field Hidden

	var fieldHidden = {
		init: function() {

			$('.js-field-hidden-input')
				.click(

					function(event) {

						$('.js-field-hidden')
							.addClass(state.active);

					}

				);


		}
	}

	// Inview Image

	var inviewImage = {
		init: function() {

			$('html')
				.waitForImages().done(

					function() {

						$('.js-inview-item')
							.each(

								function(index) {

									$(this)
										.one(
											'inview',
											function (event, visible, topOrBottomOrBoth) {

												if (visible == true) {

													var delay = (index * 1) + '00';

													$(this)
														.delay(0)
														.queue(

															function(next) {

																$(this)
																	.removeClass(state.loading);

																next();

															}

														);


												}

											}

										);

								}

							);

					}

				);

		}
	}

	// Grid

	var grid = {
		init: function() {

			$('.js-grid')
				.waitForImages().done(

					function() {

						$('.js-grid')
							.packery(
								{
									itemSelector: '.js-grid-item',
								}
							);

					}

				);

		}
	}

	// Lightbox

	var lightbox = {
		init: function() {

			$('.js-lightbox-inline-open')
				.magnificPopup(
					{
						type:'inline',
						midClick: true
					}
				);

			$('.js-lightbox-video-open')
				.magnificPopup(
					{
						type: 'iframe',
						mainClass: 'mfp-fade',
						removalDelay: 160,
						preloader: false,
						fixedContentPos: false
					}
				);

		}
	}

	// Validation

	var validation = {
		selectors: {
			form: $('.js-form-validate')
		},
		init: function() {

			this.selectors.form
    			.shapeValidate({
	    			alerts: {
						required: '*',
						email: '*',
					},
    			});

		}
	}


	// Sticky Kit

	var sticky = {
		init: function() {

			$(".js-sticky")
				.stick_in_parent(
					{
						offset_top: 20,
						bottoming: true,
						recalc_every: 1
					}
				);

		}
	}

	// Scroll To

	var scrollTo = {
		init: function() {

			$('.js-scroll-to')
				.click(

					function(event) {

						var id = $(this).data('scroll-id');

						$('.js-scroll-to')
							.removeClass(state.active);

						$(this)
							.addClass(state.active);

						$('html, body')
							.stop()
							.animate(
								{
									'scrollTop': $('.js-scroll-target[data-scroll-id=' + id + ']').offset().top -40
						 		}, 600
						 	);

						event.preventDefault();

					}

				);

		}
	}

	// Freeform

	var freeform = {
		init: function() {

			// Checkbox Group

			$('.field-container.type-checkbox_group label')
				.click(

					function(event) {

						if ( $(this).hasClass(state.active) ) {

							$(this)
								.removeClass(state.active);

							$(this)
								.children('input')
								.prop('checked', false);

						}

						else {

							$(this)
								.addClass(state.active);

							$(this)
								.children('input')
								.prop('checked', true);

						}

						event.preventDefault();

					}

				);

			// Date Picker

			$('.js-datepicker input').pickadate({
				formatSubmit: 'mm/dd/yyyy',
			});

		}
	}

	// Hero

	var hero = {
		init: function() {

			$('.js-hero')
				.waitForImages().done(

					function() {

						$('.js-hero-loader')
							.delay(2000)
							.queue(

								function() {

									$(this)
										.removeClass(state.active);

								}

							);

					}

				);

		}
	}

	// Dropdown

	var dropdown = {
		init: function() {

			$('.js-site-header-nav-dropdown-parent')
				.hover(

					function() {

						var id = $(this).data('id');

						$('.js-site-header-nav-dropdown[data-id=' + id + '], .js-site-header-nav-dropdown-parent[data-id=' + id + ']')
							.addClass(state.active);

					},

					function() {

						var id = $(this).data('id');

						$('.js-site-header-nav-dropdown[data-id=' + id + '], .js-site-header-nav-dropdown-parent[data-id=' + id + ']')
							.removeClass(state.active);

					}

				);

			$('.js-site-header-nav-dropdown')
				.hover(

					function() {

						var id = $(this).data('id');

						$('.js-site-header-nav-dropdown[data-id=' + id + '], .js-site-header-nav-dropdown-parent[data-id=' + id + ']')
							.addClass(state.active);

					},

					function() {

						var id = $(this).data('id');

						$('.js-site-header-nav-dropdown[data-id=' + id + '], .js-site-header-nav-dropdown-parent[data-id=' + id + ']')
							.removeClass(state.active);

					}

				);

		}
	}

	// Menu

	var menu = {
		init: function() {

			$('.js-site-menu-action')
				.click(

					function(event) {

						$('.js-site-menu-action, .js-site-menu, .js-site-header')
							.toggleClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// Popup

	var popup = {
	   init: function() {

	      $('.js-popup')
	         .click(
	            function(event) {

	               window.open($(this).attr('href'), 'Popup', 'menubar=no,toolbar=0,status=0,width=600,height=500');
	               event.preventDefault();

	            }
	         );

	   }
	}

// ===============================
//
// Initialise
//
// ===============================

	// Ready
	$(document)
		.ready(
			function(){
				environment.init();
				viewport.init();
				featureLinks.init();
				svg.init();
				sectionGradient.init();
				fieldHidden.init();
				inviewImage.init();
				grid.init();
				lightbox.init();
				validation.init();
				sticky.init();
				scrollTo.init();
				freeform.init();
				hero.init();
				dropdown.init();
				menu.init();
				popup.init();
			}
		);

	// Resize
	$(window)
		.resize(
			function(){

				if ($(window).width() != windowResizeWidth) {
					windowResizeWidth = viewport.getWidth(); // Required for Mobile Safari & Chrome
				}
			}
		);

	// Scroll
	$(window)
		.scroll(
			function(){
			}
		);
